<h3>Excepcionalidades</h3>

<p-messages [closable]="true"></p-messages>

<div class="pr-2 pl-2">
    <div class="row mt-12">
        <div class="col-sm-2 br-input">
            <label for="codigoOrgao">Código do Órgão:</label>
            <input #elCodigoOrgao id="codigoOrgao" name="codigoOrgao" pInputText type="text"  data-cy="campo-texto"
                [(ngModel)]="codigoOrgao" pattern="\d*" maxlength="16"/>
        </div>

        <div class="row mt-4 mb-4">
            <div class="col-sm-2 text-left">
                <button id="btnSalvar" data-cy="botao-salvar" class="primary" (click)="salvar()" type="submit" pButton>
                    Cadastrar Excepcionalidade
                </button>
            </div>
        </div>

    </div>

    <div id="resultado" class="row mt-4" *ngIf="resultado">
        <div id="quantidadeResultados" class="col-11 mb-3">
            <p *ngIf="totalResultado === 0">Nenhum resultado encontrado</p>
            <p *ngIf="totalResultado === 1">Foi encontrado 1 registro</p>
            <p *ngIf="totalResultado > 1">Foram encontrados {{ totalResultado }} registros</p>
        </div>
    </div>

    <div id="resultado">
        <div class="br-table small">
            <p-table *ngIf="!resultado || totalResultado"
                [lazy]="true" 
                (onPage)="paginar($event)"
                (onLazyLoad)="getItensPorPagina($event)" 
                [loading]="loadingResultado" 
                [first]="primeiroItemResultado"
                [totalRecords]="totalResultado!" 
                dataKey="id" 
                [rowHover]="true" 
                [responsive]="true"
                responsiveLayout="stack" 
                [autoLayout]="true" 
                [paginator]="true" 
                [rows]="10"
                [rowsPerPageOptions]="[10,20,50,100]"
                [value]="resultado" 
                [(selection)]="orgaosSelecionados" 
                dataKey="codigoOrgao" 
                [selectionPageOnly]="true" >
                
                <ng-template pTemplate="header">
                    <tr>
                        <th id="row-checkbox">
                            <p-tableHeaderCheckbox (click)="exibirBotao()"></p-tableHeaderCheckbox>
                        </th>
                        <th id="codigo-orgao">Código</th>
                        <th id="sigla-orgao">Sigla</th>
                        <th id="nome-orgao">Nome</th>
                        <th id="cpf-orgao">CPF</th>
                        <th id="data-inclusao">Data Inclusão</th>
                        <th id="cabecalho-acoes" style="width: 10%">Ações</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-resultado>
                    <tr>
                        <td>
                            <p-tableCheckbox [value]="resultado" (click)="exibirBotao()"></p-tableCheckbox>
                        </td>
                        <td>
                            {{resultado.codigoOrgao}}
                        </td>
                        <td>
                            {{resultado.siglaOrgao}}
                        </td>
                        <td>
                            {{resultado.nomeOrgao}}
                        </td>
                        <td>
                            {{resultado.cpf}}
                        </td>
                        <td>
                            {{resultado.dataInclusao | date:'short'}}
                        </td>
                        <td headers="cabecalho-acoes">
                            <button
                                pButton
                                class="br-button circle"
                                title="Excluir"
                                (click)="excluir(resultado.codigoOrgao)"
                                icon="fas fa-trash-alt"
                            ></button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <div class="row mt-12">
                <div class="row mt-4 mb-4">
                    <div class="col-sm-2 text-right">
                        <button id="btnExcluirSelecionados" *ngIf="!resultado || totalResultado"
                                class="primary" (click)="excluirSelecionados()"
                                type="submit" [disabled]="!exibirBotaoExcluirSelecionados" pButton>
                            Excluir selecionados
                        </button>
                    </div>
                </div>
        
            </div>
        </div>
        
        <p-confirmDialog [style]="{width: '450px'}" acceptLabel="Confirmar" rejectLabel="Cancelar" styleClass="br-modal"
    acceptButtonStyleClass="br-button primary small m-2" rejectButtonStyleClass="secondary small m-2"
    acceptIcon="" rejectIcon=""  icon="pi pi-exclamation-triangle"></p-confirmDialog>
    </div>
    
</div>
