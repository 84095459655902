import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { OperadorResponse } from 'src/app/core/models/operador';
import { ModalOperadorService } from 'src/app/core/services/modal-operador.service';
import { OperadorService } from 'src/app/core/services/operador.service';

@Component({
  selector: 'app-operador',
  templateUrl: './operador.component.html',
  styleUrls: ['./operador.component.css']
})
export class OperadorComponent implements OnInit {

  listaOperadores: any[] = [];
  totalOperadores = 0;
  cpfBusca = '';
  nomeBusca = '';

  page = 1;
  size = 10;
  primeiroItemResultado = 0;

  loading = false;

  constructor(
    private readonly modalOperadorService: ModalOperadorService,
    private readonly operadorService: OperadorService,
    private readonly confirmationService: ConfirmationService,
    private readonly messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.carregar();
    this.modalOperadorService.loadTrigger$.subscribe();
  }

  onModalClosed() {
    if (this.nomeBusca != '' || this.cpfBusca != '') {
      this.buscarOperador(this.cpfBusca, this.nomeBusca)
    } else {
      this.carregar()
    }
  }

  carregar() {
    this.loading = true;
    this.primeiroItemResultado = 0;
    this.operadorService.listar(this.page - 1, this.size).subscribe({
      next: (response) => {
        this.loading = false;
        this.listaOperadores = [];
        response.content.forEach(operador => {
          this.addToListaOperadores(operador);
          this.totalOperadores = response.totalElements;
        })
      }
    })
  }

  private addToListaOperadores(operador: OperadorResponse) {
    this.listaOperadores.push(
      {
        id: operador['id'],
        cpf: operador['cpf'],
        nome: operador['nome'],
        email1: operador['email_1'],
        email2: operador['email_2'],
        email3: operador['email_3'],
        dataCriacao: operador['dataCriacao'],
        dataAtualizacao: operador['dataAtualizacao']
      }
    );
  }

  addOperador() {
    this.modalOperadorService.openDialog(null);
  }

  editar(id: number) {
    this.modalOperadorService.openDialog(id);
  }

  buscarOperador(cpf: string, nome: string) {
    // se campo nome não estiver preenchido, faz busca por CPF
    // se campo nome estiver preenchido, faz busca por Nome
    const cpfWithoutMask = cpf ? cpf.replace(/\D/g, '') : '';
    if (nome == '') {
      this.operadorService.buscarPorCPF(cpfWithoutMask).subscribe({
        next: (response) => {
          this.listaOperadores = [];
          this.addToListaOperadores(response);
          this.totalOperadores = this.listaOperadores.length;
        },
        error: () => {
          this.listaOperadores = [];
          this.totalOperadores = this.listaOperadores.length;
        }
      })
    } else {
      nome = nome.trim()
      this.operadorService.buscarPorNome(this.page - 1, this.size, nome).subscribe({
        next: (response) => {
          this.listaOperadores = [];
          response.content.forEach(operador => {
            this.addToListaOperadores(operador);
          })
          this.totalOperadores = response.totalElements;
        }
      })
    }
  }

  limparPesquisa() {
    this.carregar();
    this.cpfBusca = '';
    this.nomeBusca = '';
  }

  excluir(id: number, cpf: string) {
    this.confirmationService.confirm({
      message: 'Esta ação não poderá ser desfeita.',
      header: '   Deseja excluir operador de CPF ' + cpf + '?',
      accept: () => {
        this.operadorService.excluir(id).subscribe({
          next: () => {
            this.criarMensagem("success", "Operador removido com sucesso.");
            this.carregar();
          },
          error: (error) => {
            this.criarMensagem("Error", error.error.message);
          }
        });
      }
    });
  }

  private criarMensagem(criticidade: string, mensagem: string) {
    this.messageService.clear();
    this.messageService.add({ severity: criticidade, summary: mensagem });
  }

  paginar(event: any) {
    this.page = event.first / event.rows + 1;
    this.primeiroItemResultado = event.first;
    this.carregar();
  }

  getItensPorPagina(event: any) {
    this.size = event.rows;
  }
}
