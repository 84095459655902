export class OperadorRequest {
    cpf?: string;
    nome?: string;
    email_1?: string;
    email_2?: string | null;
    email_3?: string | null;

    constructor(cpf: string, nome: string, emails: string[]) {
        this.cpf = cpf;
        this.nome = nome;
        this.email_1 = emails[0];
        this.email_2 = emails[1] || null;
        this.email_3 = emails[2] || null;
    }

    toString(): string {
        return `OperadorRequest {
            CPF: ${this.cpf ?? 'N/A'},
            Nome: ${this.nome ?? 'N/A'},
            Email 1: ${this.email_1 ?? 'N/A'},
            Email 2: ${this.email_2 ?? 'N/A'},
            Email 3: ${this.email_3 ?? 'N/A'}
        }`;
    }
}

export class OperadorResponse {
    id?: number;
    cpf?: string;
    nome?: string;
    email_1?: string;
    email_2?: string;
    email_3?: string;
    dataCriacao?: string;
    dataAtualizacao?: string;
}


export type OperadorPaginado = {
    totalElements: number;
    paginaAtual: number;
    content: OperadorResponse[]
};