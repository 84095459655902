<h3>Operadores</h3>

<p-messages [closable]="true"></p-messages>

<div class="pr-2 pl-2">
    <div class="row mt-12">
        <!-- Input busca por CPF -->
        <div class="col-sm-2 br-input">
            <label for="cpfOperador">CPF:</label>
            <p-inputMask id="cpfOperador" [(ngModel)]="cpfBusca" data-cy="cpf-busca-operador" mask="999.999.999-99" [disabled]="nomeBusca !== ''"></p-inputMask>
            <!-- <input id="cpfOperador" name="cpfOperador" pInputText type="text"  data-cy="cpf-busca-operador"
                pattern="\d*" maxlength="11" [(ngModel)]="cpfBusca" [disabled]="nomeBusca !== ''"/> -->
        </div>
        
        <!-- Input busca por Nome -->
        <div class="col-sm-2 br-input">
            <label for="nomeOperador">Nome:</label>
            <input id="nomeOperador" name="NomeOperador" pInputText type="text"  data-cy="nome-busca-operador" 
                [(ngModel)]="nomeBusca"/>
        </div>

        <div class="row mt-4 mb-4">
            <div class="col-sm-2 text-left">
                <button 
                    id="btnAddOperador" 
                    data-cy="botao-add-operador" 
                    class="primary" 
                    (click)="addOperador()" 
                    type="submit" pButton>
                    Cadastrar Operador
                </button>
            </div>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-md-12">
            <button
                pButton 
                type="button" 
                data-cy="botao-pesquisar-operador"
                label="Buscar"
                class="primary mt-3 mt-sm-0 ml-sm-3"
                (click)="buscarOperador(cpfBusca, nomeBusca)"
                [disabled]="cpfBusca.length !== 14 && nomeBusca.length == 0"
            ></button>
            <button 
                pButton 
                type="button" 
                data-cy="botao-limpar-operador" 
                label="Limpar" 
                class="secondary mt-3 mt-sm-0 ml-sm-3"
                (click)="limparPesquisa()"
            ></button>
        </div>
    </div>

    <div id="resultado" class="row mt-4" *ngIf="listaOperadores">
        <div id="quantidadeResultados" class="col-11 mb-3">
            <p *ngIf="totalOperadores === 0">Nenhum operador encontrado</p>
            <p *ngIf="totalOperadores === 1">Foi encontrado 1 operador</p>
            <p *ngIf="totalOperadores > 1">Foram encontrados {{ totalOperadores }} operadores</p>
        </div>
    </div>

    <div id="resultado">
        <div class="br-table small">
            <p-table *ngIf="!listaOperadores || totalOperadores"
                [lazy]="true" 
                [totalRecords]="totalOperadores!" 
                (onLazyLoad)="getItensPorPagina($event)" 
                [loading]="loading" 
                dataKey="id" 
                (onPage)="paginar($event)"
                [first]="primeiroItemResultado"
                [rowHover]="true" 
                [responsive]="true"
                responsiveLayout="stack" 
                [autoLayout]="true"
                [paginator]="true" 
                [rows]="10"
                [rowsPerPageOptions]="[10, 20, 50, 100]"
                [value]="listaOperadores"
                [selectionPageOnly]="true" >
                
                <ng-template pTemplate="header">
                    <tr>
                        <th id="cpf">CPF</th>
                        <th id="nome">Nome</th>
                        <th id="emails">Emails</th>
                        <th id="data_cadastro">Data de Cadastro</th>
                        <th id="data_atualização">Data da última Modificação</th>
                        <th id="cabecalho-acoes" style="width: 10%">Ações</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-listaOperadores>
                    <tr>
                        <td>
                            {{ listaOperadores.cpf | cpf }}
                        </td>
                        <td>
                            {{listaOperadores.nome}}
                        </td>
                        <td>
                            <ul>
                                <li *ngIf="listaOperadores.email1">{{ listaOperadores.email1 }}</li>
                                <li *ngIf="listaOperadores.email2">{{ listaOperadores.email2 }}</li>
                                <li *ngIf="listaOperadores.email3">{{ listaOperadores.email3 }}</li>
                            </ul>
                        </td>
                        <td>
                            {{listaOperadores.dataCriacao | date:'short'}}
                        </td>
                        <td>
                            {{ listaOperadores.dataAtualizacao ? (listaOperadores.dataAtualizacao | date:'short') : '-' }}
                        </td>
                        <td headers="cabecalho-acoes">
                            <button
                                pButton
                                class="br-button circle"
                                title="Editar"
                                (click)="editar(listaOperadores.id)"
                                icon="fas fa-pen-to-square"
                            ></button>
                            <button
                                pButton
                                class="br-button circle"
                                title="Excluir"
                                (click)="excluir(listaOperadores.id, listaOperadores.cpf)"
                                icon="fas fa-trash-alt"
                            ></button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
    <p-confirmDialog 
        [style]="{width: '500px'}" 
        acceptLabel="Confirmar" 
        rejectLabel="Cancelar" 
        styleClass="br-modal"
        acceptButtonStyleClass="br-button primary small m-2" 
        rejectButtonStyleClass="secondary small m-2"
        acceptIcon="" 
        rejectIcon=""  
        icon="pi pi-exclamation-triangle">
    </p-confirmDialog>
</div>

<app-modal-operador (modalClosed)="onModalClosed()"></app-modal-operador>