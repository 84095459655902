import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { OperadorRequest } from 'src/app/core/models/operador';
import { ModalOperadorService } from 'src/app/core/services/modal-operador.service';
import { OperadorService } from 'src/app/core/services/operador.service';

enum ModeModalOperador {
  cadastro,
  edicao
}

@Component({
  selector: 'app-modal-operador',
  templateUrl: './modal-operador.component.html',
  styleUrls: ['./modal-operador.component.css']
})
export class ModalOperadorComponent implements OnInit {
  display: boolean = false;
  cadastroOperadorForm!: FormGroup;

  modo!: ModeModalOperador;

  id!: number;

  @Output() modalClosed = new EventEmitter<void>();

  constructor(
    private readonly fb: FormBuilder,
    private readonly modalOperadorService: ModalOperadorService,
    private readonly operadorService: OperadorService,
    private readonly messageService: MessageService,
  ) { }

  ModeModalOperador = ModeModalOperador;

  ngOnInit(): void {
    this.cadastroOperadorForm = this.fb.group({
      cpf: [{ value: '', disabled: this.modo === ModeModalOperador.edicao }, [Validators.required]],
      nome: ['', [Validators.required]],
      emails: this.fb.array([this.createEmailField()], Validators.required)
    });
    this.modalOperadorService.openDialog$.subscribe((id: number | null) => {
      this.openDialog(id);
      if (id) {
        this.modo = ModeModalOperador.edicao;
      } else {
        this.modo = ModeModalOperador.cadastro;
      }
      // this.updateCpfStatus();
    });
  }

  // atauliza o estado do cpf for
  // updateCpfStatus() {
  //   if (this.modo === ModeModalOperador.edicao) {
  //     this.cadastroOperadorForm.get('cpf')?.disable();
  //   } else {
  //     this.cadastroOperadorForm.get('cpf')?.enable();
  //   }
  // }

  // Cria um novo campo de e-mail
  createEmailField(): FormGroup {
    return this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  // Adiciona validadores se necessário
  createEmailFieldWithEmail(email: string): FormGroup {
    return this.fb.group({
      email: [email, [Validators.required, Validators.email]]
    });
  }

  // Retorna o FormArray de e-mails
  get emails(): FormArray {
    return this.cadastroOperadorForm.get('emails') as FormArray;
  }

  // Adiciona um novo campo de e-mail (se ainda houver menos de 3)
  addEmailField(): void {
    if (this.emails.length < 3) {
      this.emails.push(this.createEmailField());
    }
  }

  // Remove um campo de e-mail
  removeEmailField(index: number): void {
    if (this.emails.length > 1) {
      this.emails.removeAt(index);
    }
  }

  resetEmailsToDefault(): void {
    this.emails.clear(); // Remove todos os campos do FormArray
    this.emails.push(this.createEmailField()); // Adiciona o campo padrão
  }

  headerModal(): string {
    if (this.modo == ModeModalOperador.cadastro) {
      return "Cadastrar Operador";
    }
    return "Editar Operador";
  }

  onEnter(event: any): void {
    event.preventDefault(); // Evita o comportamento padrão
    if (!this.cadastroOperadorForm.valid) {
      return;
    }
    if (this.modo === ModeModalOperador.cadastro) {
      this.cadastrar();
    } else {
      this.editar();
    }
  }

  openDialog(id: number | null) {
    this.resetEmailsToDefault()
    if (id == null) {
      this.modo = ModeModalOperador.cadastro;
      this.display = true;
    } else {
      this.modo = ModeModalOperador.edicao;
      this.id = id;
      this.operadorService.buscarPorId(id).subscribe({
        next: (response) => {
          this.emails.clear();
          this.emails.push(this.createEmailFieldWithEmail(response['email_1']!));
          if (response['email_2']) {
            this.emails.push(this.createEmailFieldWithEmail(response['email_2']));
          }
          if (response['email_3']) {
            this.emails.push(this.createEmailFieldWithEmail(response['email_3']));
          }
          this.cadastroOperadorForm.patchValue({
            cpf: response['cpf']!,
            nome: response['nome']!
          });
        }
      });
      this.display = true;
    }
  }

  private criarMensagem(criticidade: string, mensagem: string) {
    this.messageService.clear();
    this.messageService.add({ severity: criticidade, summary: mensagem });
  }

  cadastrar() {
    if (this.cadastroOperadorForm.valid) {
      let cpf = this.cadastroOperadorForm.controls['cpf'].value;
      const cpfWithoutMask = cpf ? cpf.replace(/\D/g, '') : ''; // Valor sem máscara
      let nome = this.cadastroOperadorForm.controls['nome'].value;
      const emails = this.cadastroOperadorForm.controls['emails'].value;
      let emailStrings = emails.map((emailObj: { email: string }) => emailObj.email);
      const operator = new OperadorRequest(cpfWithoutMask, nome, emailStrings);
      console.log(operator);
      this.operadorService.cadastrar(operator).subscribe({
        next: (response) => {
          this.criarMensagem("success", `Operador com CPF ${response.cpf} cadastradado com sucesso.`);
        },
        error: (error) => {
          if(error.error.errors) {
            let err = error.error.errors;
            const errorMessage = Object.values(err).join(' - ');
            this.criarMensagem("error", errorMessage);
          } else if (error.error.message) {
            this.criarMensagem("error", error.error.message);
          } else {
            this.criarMensagem("error", "Erro desconhecido");
          }
          this.display = false
          this.modalClosed.emit();
        },
        complete: () => {
          this.display = false
          this.modalClosed.emit();
        }
      })
    }
  }

  editar() {
    if (this.cadastroOperadorForm.valid) {
      let cpf = this.cadastroOperadorForm.controls['cpf'].value;
      const cpfWithoutMask = cpf ? cpf.replace(/\D/g, '') : ''; // Valor sem máscara
      let nome = this.cadastroOperadorForm.controls['nome'].value;
      const emails = this.cadastroOperadorForm.controls['emails'].value;
      let emailStrings = emails.map((emailObj: { email: string }) => emailObj.email);
      const operator = new OperadorRequest(cpfWithoutMask, nome, emailStrings);
      this.operadorService.atualizar(this.id, operator).subscribe({
        next: (response) => {
          this.criarMensagem("success", `Operador com CPF ${response.cpf} atualizado com sucesso.`);
        },
        error: (error) => {
          if(error.error.errors) {
            let err = error.error.errors;
            const errorMessage = Object.values(err).join(' - ');
            this.criarMensagem("error", errorMessage);
          } else if (error.error.message) {
            this.criarMensagem("error", error.error.message);
          } else {
            this.criarMensagem("error", "Erro desconhecido");
          }
          this.display = false
          this.modalClosed.emit();
        },
        complete: () => {
          this.display = false
          this.modalClosed.emit();
        }
      })
    }
  }

  cancelar() {
    this.display = false;
    this.cadastroOperadorForm.reset();
  }
}
