import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { OperadorPaginado, OperadorRequest, OperadorResponse } from '../models/operador';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root'
})
export class OperadorService {

  private readonly urlBase = environment.HOST_SEGURANCA_GESTOR + "/operadores";

  constructor(private readonly http: HttpClient) { }

  listar(page: number, size: number) : Observable<OperadorPaginado> {
    const url = this.urlBase;
    let params = new HttpParams();
		if (page >= 0) { params = params.set('page', page); }
		if (size >= 0) { params = params.set('size', size); }
    const options = { ...httpOptions, params };
    return this.http.get<OperadorPaginado>(url, options);
  }

  cadastrar(request: OperadorRequest) : Observable<OperadorResponse> {
    const url = this.urlBase;
    const data = JSON.stringify(request);
    return this.http.post<OperadorResponse>(url, data, httpOptions);
  }

  atualizar(id: number, request: OperadorRequest) : Observable<OperadorResponse> {
    const url = `${this.urlBase}/${id}`;
    const data = JSON.stringify(request);
    return this.http.put<OperadorResponse>(url, data, httpOptions);
  }

  buscarPorId(id: number) : Observable<OperadorResponse> {
    const url = `${this.urlBase}/${id}`;
    return this.http.get<OperadorResponse>(url, httpOptions);
  }

  buscarPorCPF(cpf: string) : Observable<OperadorResponse> {
    const url = `${this.urlBase}/by-cpf/${cpf}`;
    return this.http.get<OperadorResponse>(url, httpOptions);
  }

  buscarPorNome(page: number, size: number, nome: string) : Observable<OperadorPaginado> {
    const url = `${this.urlBase}/by-name`;
    let params = new HttpParams();
		if (page >= 0) { params = params.set('page', page); }
		if (size >= 0) { params = params.set('size', size); }
    if (nome != "") { params = params.set('nome', nome); }
    const options = { ...httpOptions, params };
    return this.http.get<OperadorPaginado>(url, options);
  }

  excluir(id: number) : Observable<void> {
    const url = `${this.urlBase}/${id}`;
    return this.http.delete<void>(url, httpOptions);
  }
}
