import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HistoricoMovimentacoesService } from 'src/app/core/services/historico-movimentacoes.service';
import { ModalService } from 'src/app/core/services/modal.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {

  display: boolean = false;

  justificativa: string = '';

  modelForm!: FormGroup;

  currentIdMovimentacao: string = '';

  constructor(
    private readonly fb: FormBuilder,
    private readonly modalService: ModalService,
    private readonly historicoMovimentacaoService: HistoricoMovimentacoesService,
  ) { }
  
  ngOnInit(): void {
    this.modelForm = this.fb.group({
      justificativa: ['', [Validators.required, Validators.minLength(10)]], 
    });
    this.modalService.openDialog$.subscribe((id: string) => {
      this.openDialog(id);
    });
  }

  openDialog(id: string) {
    this.display = true;
    this.currentIdMovimentacao = id;
  }

  cancelar() {
    this.display = false;
    this.justificativa = '';
    this.currentIdMovimentacao = '';
    this.modelForm.get('justificativa')?.markAsUntouched();
  }

  salvar() {
    this.display = false;
    this.historicoMovimentacaoService.tratarManualmente(this.currentIdMovimentacao, this.justificativa).subscribe({
      next: (response) => {
        this.modalService.triggerLoad();
      },
      error: (error) => console.error('Erro na requisição PUT ', error),
    });
  }
}
