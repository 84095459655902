<p-dialog [header]="headerModal()" [(visible)]="display" [modal]="true" [style]="{width: '60vw'}" [baseZIndex]="100000"
    [draggable]="false" [resizable]="false" (onHide)="cancelar()">
    <div class="mt-2">
        <form [formGroup]="cadastroOperadorForm" (keydown.enter)="onEnter($event)">
            <div class="cadastro-operador-form">
                <div class="col-sm-2 br-input col-md-12">
                    <label for="cpfOperador">CPF:</label>
                    <p-inputMask formControlName="cpf" data-cy="operador-form-cpf" mask="999.999.999-99" [readonly]="modo === ModeModalOperador.edicao"></p-inputMask>
                </div>

                <div class="col-sm-2 br-input col-md-12">
                    <label for="nomeOperador">Nome:</label>
                    <input type="text" data-cy="operador-form-nome" pInputText formControlName="nome" />
                </div>

                <div class="col-sm-2 br-input col-md-12" formArrayName="emails">
                    <label for="emailsOperador">Emails:</label>
                    <div *ngFor="let email of emails.controls; let i = index" [formGroupName]="i" class="email-field" style="display: flex; align-items: center;">
                        <input type="email" formControlName="email" style="flex: 1;" />
                        <button *ngIf="emails.length > 1" 
                            type="button" 
                            (click)="removeEmailField(i)" 
                            style="margin-left: 8px;">
                            <i class="fa-solid fa-trash"></i>
                        </button>
                    </div>
                
                    <!-- Botão para adicionar mais campos de email (até 3) -->
                    <div class="button-container">
                        <button 
                            type="button" 
                            (click)="addEmailField()" 
                            [disabled]="emails.length >= 3"
                            icon="fas fa-plus"
                        >Adicionar Email</button>
                    </div>
                </div>
            </div>
            <div class="p-d-flex p-jc-end mt-2">
                <button 
                    data-cy="botao-cancelar-cadastro-operador" 
                    (click)="cancelar()" 
                    class="p-button secondary mr-2"
                >Cancelar</button>
                <button 
                    *ngIf="modo === ModeModalOperador.cadastro"
                    data-cy="botao-salvar-cadastro-operador" 
                    (click)="cadastrar()" 
                    type="submit"
                    class="p-button primary ml-2"
                    [disabled]="cadastroOperadorForm.invalid"
                >Salvar</button>
                <button 
                    *ngIf="modo === ModeModalOperador.edicao"
                    data-cy="botao-salvar-cadastro-operador" 
                    (click)="editar()" 
                    type="submit"
                    class="p-button primary ml-2"
                    [disabled]="cadastroOperadorForm.invalid"
                >Salvar</button>
            </div>
        </form>
    </div>
</p-dialog>
