<p-dialog header="Resolver manualmente" [(visible)]="display" [modal]="true" [style]="{width: '60vw'}" [baseZIndex]="100000"
    [draggable]="false" [resizable]="false" (onHide)="cancelar()">
    <p class="p-m-0">Informe abaixo a ação executada manualmente para tratamento do erro reportado</p>
    <div class="mt-2">
        <form [formGroup]="modelForm">
            <div class="br-textarea">
                <textarea 
                    formControlName="justificativa" 
                    data-cy="textara-justificativa" 
                    [(ngModel)]="justificativa" 
                    rows="5" 
                    pInputTextarea 
                    autoResize="autoResize"
                ></textarea>
                <small 
                    class="p-error" 
                    *ngIf="modelForm.get('justificativa')?.touched && modelForm.get('justificativa')?.errors?.['required']"
                >É obrigatório registrar informação sobre a ação manual executada</small>
                <small 
                    class="p-error" 
                    *ngIf="modelForm.get('justificativa')?.touched && modelForm.get('justificativa')?.hasError('minlength')"
                >A informação deve conter no mínimo 10 caracteres</small>
            </div>
            <div class="p-d-flex p-jc-end mt-2">
                <button 
                    data-cy="botao-cancelar-acao-manual" 
                    (click)="cancelar()" 
                    class="p-button secondary mr-2"
                >Cancelar</button>
                <button 
                    data-cy="botao-salvar-acao-manual" 
                    (click)="salvar()" 
                    type="submit"
                    class="p-button primary ml-2"
                    [disabled]="modelForm.invalid"
                >Salvar</button>
            </div>
        </form>
    </div>
</p-dialog>
