import { Component, Input, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';

import { Excepcionalidade } from '../../core/models/excepcionalidade';
import { ExcepcionalidadeService } from '../../core/services/excepcionalidade.service';
import { UiService } from '../../core/services/ui.service';

@Component({
  selector: 'app-excepcionalidade',
  templateUrl: './excepcionalidade.component.html'
})
export class ExcepcionalidadeComponent implements OnInit {
	
  	@Input() codigoOrgao = '';
	orgaosSelecionados: Excepcionalidade[] = [];

	excepcionalidades!: Excepcionalidade[];
  	pesquisarAoCarregar = true;
	habilitarFiltroCadastradoUsuario = false;
	limparFormularioPesquisa = false;
	resultado: any[] = [];
	loadingResultado = false;
	totalResultado = 0;
	pagina = 1;
	itensPorPagina = 10;
	primeiroItemResultado = 0;
	colunas: any;
	operacao?: string;
	exibirBotaoExcluirSelecionados? = false;

	constructor(
		private excepcionalidadeService: ExcepcionalidadeService,
		private confirmationService: ConfirmationService,
		private messageService: MessageService,
		private ui: UiService
	) {}

	ngOnInit(): void {
		this.load();
		this.exibirBotao();
	}
 
	load() {
		this.exibirBotao();
		this.loadingResultado = true;
		this.primeiroItemResultado = 0;
		this.excepcionalidadeService.findAllPaginado(this.pagina - 1, this.itensPorPagina)
		.subscribe(retorno => {
			this.loadingResultado = false;
			this.resultado = [];
			if (retorno !== null) {
				//retorno.content.forEach(dado => {
				retorno.excepcionalidades.forEach(dado => {
					this.resultado.push(
						{
							cpf: dado["cpf"],
							codigoOrgao: dado["codigoOrgao"],
							nomeOrgao: dado["nomeOrgao"],
							siglaOrgao: dado["siglaOrgao"],
							dataInclusao: dado["dataInclusao"]
						}
					);
				})
				this.totalResultado = retorno.totalElements;
			}
		})
		
	}

  	salvar() {
		if (this.codigoOrgao === null || this.codigoOrgao === "") {
			this.criarMensagem("error", "Código do Órgão é obrigatório.")
		} else {
			this.excepcionalidadeService.salvar(this.codigoOrgao).subscribe(
				{
					next: () => {
						this.load()
						this.criarMensagem("success", "Excepcionalidade cadastrada com sucesso.")},
					error: (erro) => this.criarMensagem("error", erro.error.message)
				})
		}
  	}

	paginar(event: any) {
		this.pagina = event.first / event.rows + 1;
		this.primeiroItemResultado = event.first;
		this.load();
	}

	getItensPorPagina(event: any) {
		this.itensPorPagina = event.rows;
	}

	excluir(codigoOrgao: string) {
		this.confirmationService.confirm({
			message: 'Esta ação não poderá ser desfeita.',
			header: '   Deseja excluir a excepcionalidade para o órgão ' + codigoOrgao + '?',
			accept: () => {
				this.excepcionalidadeService.excluir(codigoOrgao).subscribe(
				{
					next: () => {
						this.load()
						this.criarMensagem("success", `Excepcionalidade para o Órgão ${codigoOrgao} excluída com sucesso.`)},
			 		error: (erro) => this.criarMensagem("error", erro.error.message)
				})
			}
		});
	}

	excluirSelecionados() {
		this.confirmationService.confirm({
			
			message: 'Esta ação não poderá ser desfeita.',
			header: 'Deseja excluir a(s) excepcionalidade(s) selecionada(s)?',
			accept: () => {
				this.orgaosSelecionados.forEach((dado => {
					this.excepcionalidadeService.excluir(dado.codigoOrgao).subscribe(
						{
							next: () => {
								this.criarMensagem("success", `Excepcionalidades excluídas com sucesso.`)
								this.orgaosSelecionados = []
								this.load()},
							 error: (erro) => this.criarMensagem("error", erro.error.message)
						})	
				})) 
			}
		});

	}

	private criarMensagem(criticidade: string, mensagem: string) {
		this.messageService.clear();
		this.messageService.add({severity: criticidade, summary: mensagem});
	}

	public exibirBotao() {
		if (this.orgaosSelecionados.length > 0) {
			this.exibirBotaoExcluirSelecionados = true;
		} else {
			this.exibirBotaoExcluirSelecionados = false;
		}
	}

}
