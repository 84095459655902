import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalOperadorService {

  private openDialogSource = new Subject<number | null>();
  openDialog$ = this.openDialogSource.asObservable();

  private loadTrigger = new Subject<void>();
  loadTrigger$ = this.loadTrigger.asObservable();

  openDialog(id: number | null) {
    this.openDialogSource.next(id);
  }

  triggerLoad() {
    this.loadTrigger.next();
  }
}