import { ApiSegurancaService, AuthInterceptor, SegurancaService, SigepeSegurancaModule } from '@sigepe/ngx-sigepe-seguranca';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ButtonModule } from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import { CodigoDescricaoPipe } from './core/pipes/codigo-descricao.pipe';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CpfPipe } from './core/pipes/cpf.pipe';
import { DescricaoOrgaoComponent } from './componentes/descricao-orgao/descricao-orgao.component';
import { DescricaoUorgComponent } from './componentes/descricao-uorg/descricao-uorg.component';
import {DropdownModule} from 'primeng/dropdown';
import { ExcepcionalidadeComponent } from './componentes/excepcionalidade/excepcionalidade.component';
import { HistoricoMovimentacoesDetalheComponent } from './componentes/historico-movimentacoes-detalhe/historico-movimentacoes-detalhe.component';
import { HistoricoMovimentacoesFiltrosComponent } from './componentes/historico-movimentacoes-filtros/historico-movimentacoes-filtros.component';
import { HistoricoMovimentacoesPesquisaComponent } from './componentes/historico-movimentacoes-pesquisa/historico-movimentacoes-pesquisa.component';
import { HomeComponent } from './componentes/home/home.component';
import {InputMaskModule} from 'primeng/inputmask';
import {InputNumberModule} from 'primeng/inputnumber';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { SigepeMainLayoutModule } from '@sigepe/sigepe-template';
import { TableModule } from 'primeng/table';
import { environment } from "../environments/environment";
import { SgpSegurancaDirective } from './core/directives/sgp-seguranca.directive';
import { DialogModule } from 'primeng/dialog';
import { ModalComponent } from './componentes/modal/modal.component';
import { OperadorComponent } from './componentes/operador/operador.component';
import { ModalOperadorComponent } from './componentes/modal-operador/modal-operador.component';


@NgModule({
  declarations: [
    AppComponent, 
    ExcepcionalidadeComponent,
    CpfPipe, 
    DescricaoOrgaoComponent,
    ModalComponent,
    HistoricoMovimentacoesFiltrosComponent, 
    HistoricoMovimentacoesDetalheComponent, 
    HistoricoMovimentacoesPesquisaComponent,
    DescricaoUorgComponent, 
    CodigoDescricaoPipe, 
    HomeComponent, 
    SgpSegurancaDirective,
    OperadorComponent,
    ModalOperadorComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    ButtonModule,
    CalendarModule,
    ConfirmDialogModule,
    DropdownModule,
    FormsModule,
    InputMaskModule,
    InputNumberModule,
    HttpClientModule,
    MessageModule,
    MessagesModule,
    ReactiveFormsModule,
    SigepeSegurancaModule.forRoot(environment.SEGURANCA_CONFIG),
    SigepeMainLayoutModule.forRoot(environment),
    TableModule,

    DialogModule
  ],
  providers: [
    SegurancaService, 
    ApiSegurancaService,
    ConfirmationService, 
    MessageService, 
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
