import { AuthGuard, SigepeSegurancaModule } from '@sigepe/ngx-sigepe-seguranca';
import { RouterModule, Routes } from '@angular/router';

import { ExcepcionalidadeComponent } from './componentes/excepcionalidade/excepcionalidade.component';
import { HistoricoMovimentacoesDetalheComponent } from './componentes/historico-movimentacoes-detalhe/historico-movimentacoes-detalhe.component';
import { HistoricoMovimentacoesPesquisaComponent } from './componentes/historico-movimentacoes-pesquisa/historico-movimentacoes-pesquisa.component';
import { HomeComponent } from './componentes/home/home.component';
import { NgModule } from '@angular/core';
import { SigepeMainLayoutModule } from '@sigepe/sigepe-template';
import { environment } from './../environments/environment';
import { OperadorComponent } from './componentes/operador/operador.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
		data: {
      title: 'Início', 
      breadcrumb: 'Início', 
      showInMenu: false, 
    },
		canActivate: [ AuthGuard ]
  },
  {
		path: 'operadores',
		component: OperadorComponent,
		data: {
      title: 'Operadores', 
      breadcrumb: 'Operadores', 
      showInMenu: true,
      transactions: ['SEG_GERENCIAR_OPERADOR'],
    },
		canActivate: environment.GUARDS_AND_TRANSACTION
	},
  {
		path: 'excepcionalidade',
		component: ExcepcionalidadeComponent,
		data: {
      title: 'Excepcionalidades', 
      breadcrumb: 'Excepcionalidades', 
      showInMenu: true, 
      transactions: ['SEG_GESTOR_MANTER_EXCEPCIONALIDADE'],
    },
		canActivate: environment.GUARDS_AND_TRANSACTION
	},
  {
		path: 'historico',
		component: HistoricoMovimentacoesPesquisaComponent,
		data: {
      title: 'Histórico de Movimentações', 
      breadcrumb: 'Histórico de Movimentações', 
      showInMenu: true,
      transactions: ['SEG_GESTOR_CONSULTAR_HISTORICO_MOVIMENTACOES'],
    },
		canActivate: environment.GUARDS_AND_TRANSACTION
	},
  {
		path: 'historico/:id',
		component: HistoricoMovimentacoesDetalheComponent,
		data: {
      title: 'Detalhamento da Movimentação', 
      breadcrumb: 'Detalhamento da Movimentação', 
      showInMenu: false,
      transactions: ['SEG_GESTOR_CONSULTAR_HISTORICO_MOVIMENTACOES'],
    },
		canActivate: environment.GUARDS_AND_TRANSACTION
	}
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', useHash: false }),
		SigepeMainLayoutModule,
		SigepeSegurancaModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
